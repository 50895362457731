<template>
  <div id="app">
    <div class="header">
      <div class="title">
        <div class="logo">堃</div>
        <div class="desc">系列作品</div>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="{
            active: currRouteName === 'home' || currRouteName === 'miniProgram',
          }"
        >
          <router-link to="/miniProgram">小程序</router-link>
        </div>
        <div class="tab" :class="{ active: currRouteName === 'website' }">
          <router-link to="/website">网站</router-link>
        </div>
      </div>
      <div class="about"></div>
    </div>
    <div class="body">
      <router-view />
    </div>
    <div class="footer">
      <div class="right">Copyright © 2022 LGngKn.</div>
      <a href="https://beian.miit.gov.cn/" target="_blank">
        闽ICP备2022012288号。
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currRouteName() {
      return this.$route.name;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  color: #1d1d1f;
}
a {
  text-decoration: none;
  color: #1d1d1f;
}
#app {
  font-size: 1.6vh;
  .header {
    height: 5.5vh;
    display: flex;
    box-shadow: 0 0.2vh 3vh #00000017;
    .title {
      flex: 0 0 auto;
      display: flex;
      align-items: flex-end;
      .logo {
        padding: 0.2vh 1vh 0.2vh 4vh;
        font-size: 4vh;
        font-weight: bold;
        color: rgb(0, 73, 129);
      }
      .desc {
        padding: 0.8vh 0;
      }
    }
    .tabs {
      flex: 1;
      display: flex;
      justify-content: center;
      .tab {
        width: 12vh;
        margin: 0 2vh;
        position: relative;
        a {
          display: block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.active {
          font-weight: bold;
        }
        &.active ::after {
          content: "";
          display: block;
          position: absolute;
          left: 20%;
          bottom: 0;
          height: 0.3vh;
          width: 60%;
          background: #1d1d1f;
        }
        &:hover ::after {
          content: "";
          display: block;
          position: absolute;
          left: 20%;
          bottom: 0;
          height: 0.3vh;
          width: 60%;
          background: #1d1d1f;
        }
      }
    }
    .about {
      width: 15vh;
    }
  }
  .body {
    min-height: calc(100vh - 9.5vh);
  }
  .footer {
    font-size: 1.2vh;
    color: #6e6e73;
    height: 4vh;
    background: #f5f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    .right {
      padding-right: 1vh;
      color: #6e6e73;
    }
    a {
      color: #6e6e73;
    }
  }
}
@media screen and (max-width: 1000px) {
  #app {
    .header {
      height: 40px;
      display: flex;
      box-shadow: 0 2px 28px #00000017;
      .title {
        .logo {
          padding: 3px 10px 3px 15px;
          font-size: 26px;
          font-weight: bold;
          color: rgb(0, 73, 129);
        }
        .desc {
          display: none;
        }
      }
      .tabs {
        .tab {
          font-size: 16px;
          width: 80px;
          margin: 0 10px;
        }
      }
      .about {
        width: 51px;
      }
    }
    .body {
      min-height: calc(100vh - 80px);
    }
    .footer {
      height: 40px;
    }
  }
}
</style>
