<template>
  <div class="miniProgram-page">
    <div class="title">嗨啤酒桌助手</div>
    <div class="main-content">
      <swiper
        :style="{
          '--swiper-navigation-color': '#999',
          '--swiper-pagination-color': '#999',
        }"
        :slides-per-view="slidesPerView"
        :space-between="20"
        :loop="true"
        :pagination="true"
        :navigation="true"
      >
        <swiper-slide v-for="n in 8" :key="n">
          <div class="img-wrapper">
            <img :src="jpgList[n - 1]" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="qrcode">
        <img src="../assets/img/qrcode.jpg" />
        <div class="tip">微信小程序二维码</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

import jpg1 from "@/assets/img/1.jpg";
import jpg2 from "@/assets/img/2.jpg";
import jpg3 from "@/assets/img/3.jpg";
import jpg4 from "@/assets/img/4.jpg";
import jpg5 from "@/assets/img/5.jpg";
import jpg6 from "@/assets/img/6.jpg";
import jpg7 from "@/assets/img/7.jpg";
import jpg8 from "@/assets/img/8.jpg";

export default {
  data() {
    return {
      slidesPerView: 1,
      clientWidth: "",
      jpgList: [jpg1, jpg2, jpg3, jpg4, jpg5, jpg6, jpg7, jpg8],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.setClientWidth();
    window.onresize = () => {
      this.setClientWidth();
    };
  },
  methods: {
    setClientWidth() {
      this.clientWidth = document.documentElement.clientWidth;
      console.log("this.clientWidth: ", this.clientWidth);
      if (this.clientWidth > 1670) {
        this.slidesPerView = 3;
        return;
      }
      if (this.clientWidth > 1070) {
        this.slidesPerView = 2;
        return;
      }
      if (this.clientWidth > 600) {
        this.slidesPerView = 1;
        return;
      }
      if (this.clientWidth > 0) {
        this.slidesPerView = 1.5;
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.miniProgram-page {
  .title {
    font-size: 2.5vh;
    font-weight: bold;
    text-align: center;
    padding-top: 8vh;
  }
  .main-content {
    display: flex;
    padding: 6vh 0;
    .swiper-container {
      flex: 1;
      margin: 0 3vw;
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        .img-wrapper {
          height: 65vh;
          border-radius: 3vh;
          overflow: hidden;
          img {
            height: 100%;
          }
        }
      }
    }
    .qrcode {
      flex: 0 0 auto;
      width: 25vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
      .tip {
        padding: 2vh;
        font-size: 2vh;
        font-weight: bold;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .miniProgram-page {
    padding: 20px 10px;
    .title {
      font-size: 20px;
      padding-top: 5px;
      padding-bottom: 20px;
    }
    .main-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      .swiper-container {
        width: 100%;
        margin: 0;
        .swiper-slide {
          .img-wrapper {
            height: 55vh;
          }
        }
      }
      .qrcode {
        width: 100%;
        padding-top: 25px;
        img {
          width: 30%;
        }
        .tip {
          padding-top: 15px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>