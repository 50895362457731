import Vue from 'vue'
import VueRouter from 'vue-router'
import miniProgram from '../views/miniProgram.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: miniProgram
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import(/* webpackChunkName: "miniProgram" */ '../views/stock.vue')
  },
  {
    path: '/miniProgram',
    name: 'miniProgram',
    component: () => import(/* webpackChunkName: "miniProgram" */ '../views/miniProgram.vue')
  },
  {
    path: '/website',
    name: 'website',
    component: () => import(/* webpackChunkName: "website" */ '../views/website.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
